import { render, staticRenderFns } from "./comUpload_pc.vue?vue&type=template&id=9ae0668e&scoped=true&"
import script from "./comUpload_pc.vue?vue&type=script&lang=js&"
export * from "./comUpload_pc.vue?vue&type=script&lang=js&"
import style0 from "./comUpload_pc.vue?vue&type=style&index=0&id=9ae0668e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ae0668e",
  null
  
)

export default component.exports