<template>
    <div class="com-upload">
        <transition-group class="transition-wrapper" name="sort">
            <div
                v-for="(item,index) in fileList"
                :key="item"
                class="sort-item item"
                :draggable="true"
                @dragstart="dragstart(item)"
                @dragenter="dragenter(item, $event)"
                @dragend="dragend(item, $event)"
                @dragover="dragover($event)"
            >
                <van-image
                    @click="preview(index)"
                    width="100%"
                    height="100%"
                    fit="cover"
                    :src="item + '?x-oss-process=style/compress'"
                />
                <div @click.stop="del(index)" class="icon"></div>
            </div>
        </transition-group>
        <div class="btn-box flex">
          <div class="btn1" v-show="fileList.length<Length">
              <input class="fileInput" multiple="multiple" ref="file" accept="image/*" type="file"  v-on:change="uploadClick" />
              <van-icon name="photo" color="#fff" size="18" />
              添加
          </div>
        </div>
    </div>
</template>

<script>
import { ImagePreview, Icon } from "vant";
import { ossUpload } from "../api";
import { Image } from "vant";

import draggable from "vuedraggable";
export default {
    name: "ComUpload",
    props: {
        fileList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        Length: {
            type: Number,
            default: 100,
        },
        
    },
    watch: {},
    computed: {},
    components: {
        [Image.name]: Image,
        [Icon.name]: Icon,
        draggable,
    },
    data() {
        return {
            oldData: null,
            newData: null,
        };
    },
    methods: {
        preview(index) {
          ImagePreview({
            images: this.fileList,
            startPosition: index
          })
        },
        del(index) {
          this.fileList.splice(index, 1)
          this.$emit('update:fileList', this.fileList)
        },

        uploadClick(e) {
            var list = e.target.files
            
            this.$toast.loading({
                duration: 0,
                forbidClick: true
            })
            var arr = []
            for (var item of list) {
                var p = new Promise((resolve, reject) => {

                    let param = new FormData() // 创建form对象
                    param.append('file', item, item.name) // 通过append向form对象添加数据
                    console.log(param.get('file'));
                    ossUpload(param).then(res => {
                        resolve(res.data.data)
                    }).catch(()=>{
                        this.$toast.clear()
                    })
                })
                arr.push(p)
            }
            Promise.all(arr).then((values) => {
                console.log(values);
                var fileList = this.fileList.concat(values)
                this.$emit('update:fileList', fileList)
            }).finally(() => {
                this.$toast.clear()
            })
        },
        dragstart(value) {
            console.log(value);
            this.oldData = value;
        },

        // 记录移动过程中信息
        dragenter(value, e) {
            console.log(value);
            this.newData = value;
            e.preventDefault();
        },

        // 拖拽最终操作
        dragend(value, e) {
            console.log(this.oldData);
            console.log(this.newData);
            if (this.oldData !== this.newData) {
                let oldIndex = this.fileList.indexOf(this.oldData);
                let newIndex = this.fileList.indexOf(this.newData);
                let newItems = [...this.fileList];
                // 删除老的节点
                newItems.splice(oldIndex, 1);
                // 在列表中目标位置增加新的节点
                newItems.splice(newIndex, 0, this.oldData);
                this.$emit('update:fileList', [...newItems])
            }
        },

        // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
        dragover(e) {
            e.preventDefault();
        },
    },
};
</script>
<style scoped>
.sort-move {
    transition: transform 0.3s;
}
.com-upload{
    width: 100%;
}
.transition-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.com-upload .item {
    position: relative;
    max-width: 300px;
    max-height: 300px;
    width: 30vw;
    height: 30vw;
    margin-right: 2vw;
    margin-bottom: 3vw;
}
.com-upload .item>>>.van-image{
    
    width: 100%;
    height: 100%;
}
.com-upload .item .icon{
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  /* pointer-events:auto; */
  z-index: 10;
  background-image: url(../assets/icon-del.png);
  background-size: 20px 20px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  /* transform: translateZ(1px); */
}
.btn-box{
    position: relative;
    width: 100%;
    z-index: 10;
}
.com-upload .btn1{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 126px;
    height: 34px;
    background: linear-gradient(to bottom, #7fa9ff, #6497ff);
    box-shadow: 2px 3px 11px 0px rgba(255, 255, 255, 0.26) inset,-1px -1px 9px 0px rgba(90, 140, 243, 0.67) inset;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    margin-right: 20px;
}
.fileInput{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
</style>